<template>
	<div class="b-page-wrapper administrator">
		<div class="b-head-block">
			<div class="b-container">
				<div class="b-head-block__wrapper">
					<h1 class="b-title b-title--h1">Справочник МПНКО</h1>
				</div>
			</div>
		</div>
		<div class="b-container">
			<div class="b-wrapper-page b-wrapper-page--users-mpnko">
				<!-- ФИЛЬТР ПОИСКА -->
				<div :class="{'b-filter': true, open: open}">
					<button @click="open=!open" class="b-filter__button">
						<iconSvg v-if="!open" class="b-icon b-icon--filter icon-filter icon"/>
						<span v-if="!open" class="open">Фильтры</span>
						<i v-if="open" class="b-icon b-icon--filter-close icon-close"></i>
						<span v-if="open" class="close">Скрыть</span>
					</button>
					<form class="b-filter__hidden" style="align-items: none!important;" action="javascript:void(0);">
						<div class="b-filter__form">
							<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search input-group">
								<div class="b-select b-select--dual b-select--clinical b-select--status">
									<v-select placeholder="Наименование" :reduce="obj => obj.name" v-model="search.name" :options="listCompaniesFilter" label="name">
										<template v-slot:no-options="{ search, searching }">
											<template v-if="searching"> Поиск не дал результатов <em>{{ search }}</em>.</template>
										</template>
									</v-select>
								</div>
								<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search b-input--fio" style="height: 50px;">
									<input v-model="search.inn" class="b-input__input-field b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search b-input__input-field--users-mpnko" id="search-filter" type="number" placeholder="Поиск по ИИН" name="search-filter-name"/>
									<iconSearchSvg class="iconSearch"/>
									<label class="b-input__label" for="search-filter">Поиск по ИИН</label>
								</div>
								<div class="b-input b-input--dual b-input--dual b-input--clinical b-input--search b-input--fio" style="height: 50px;">
									<input v-model="search.email" class="b-input__input-field b-input__input-field--dual b-input__input-field--dual b-input__input-field--clinical b-input__input-field--search b-input__input-field--users-mpnko" id="search-filter" type="search" placeholder="Поиск по e-mail" name="search-filter-name"/>
									<iconSearchSvg class="iconSearch"/>
									<label class="b-input__label" for="search-filter">Поиск по e-mail</label>
								</div>
							</div>
						</div>
						<div style="height: 100%;">
							<button style="margin-bottom:24px;padding-top:16px;" @click="searchFunc()" class="b-button b-button--fill">Применить</button>
						</div>
					</form>
				</div>
				<div class="b-table-list b-table-list--users b-table-list--administrator">
					<div class="b-table-list__table">
						<div class="b-table-list__top">

							<!-- ДЛЯ ВЕРСИИ ДЕСКТОПА -->
							<div class="b-table-list__desktop">
							<div class="b-table-list__wrapper-top">
								<div class="b-table-list__left">
									<div class="b-table-list__all">Всего записей:<span>{{listCompanies.length}}</span>
									</div>
									<router-link to="/addCompanies">
										<button class="b-button b-button--new-user b-button--empty b-button--full">
											<iconPlusSvg class="b-icon b-icon--plus icon-plus" />
											Добавить
										</button>
									</router-link>
								</div>
							</div>
							</div>

							<!-- ДЛЯ ВЕРСИИ ТЕЛЕФОНА -->
							<div class="b-table-list__mobile">
							<router-link to="/addCompanies">
								<button class="b-button b-button--new-user b-button--empty b-button--full">
									<iconPlusSvg class="b-icon b-icon--plus icon-plus" />
									Добавить
								</button>
							</router-link>
							<div class="b-table-list__wrapper-top">
								<div class="b-table-list__left">
									<div class="b-table-list__all">Всего записей:<span>{{listCompanies.length}}</span>
									</div>
								</div>
								<div class="b-table-list__right">
									<a class="b-link b-link--report-mpnlo" href="javascript:void(0);" title="">
									<iconDownloadSvg class="b-icon b-icon--report icon-download"/>
										<span class="mobile">Отчет</span>
									</a>
								</div>
							</div>
							</div>

							</div>
						<div class="b-table-list__scroll--table js-horizontal-scroll">
							<table class="b-table-list__table b-table-list__table--scroll-width">
								<thead>
									<tr>
										<th></th>
										<th onselectstart="return false" onmousedown="return false" v-for="item in columns">
											<span @click="item.view ? sortFunc(item) : ''" :style="item.view ? 'cursor:pointer' : ''">{{item.name}}</span>

											<div v-show="checkColumn == item.nameEng" class="b-table-list__arrow">
												<button v-if="item.sort" class="b-table-list__top-button">
													<iconSortupSvg class="b-icon b-icon--table-button icon-sort-up"/>
												</button>
												<button v-else class="b-table-list__bottom-button">
													<iconSortdownSvg class="b-icon b-icon--table-button icon-sort-up"/>
												</button>
											</div>
										</th>
										<th>Действия</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, index) in listCompanies">
										<td></td>
										<td>
											{{row.name ? row.name : '---'}}
										</td>
										<td>
											{{row.inn ? row.inn : '---'}}
										</td>
										<td>
											{{row.address}}
										</td>
										<td>{{row.phone}}</td>
										<td>{{row.email}}</td>
										<td style="padding-left:0">
											<a class="b-table-list__key mr-2">
												<div style="position: relative;bottom: 4px;">
													<iconEditSvg @click="editRow(row)" class="b-icon b-icon--edit icon-edit pointer" />
												</div>
											</a>
											<a class="b-table-list__key mr-2">
												<div style="position: relative;bottom: 4px;">
													<iconCloseSvg @click="deleteItems(row)" class="b-icon b-icon--edit icon-edit pointer"/>
												</div>
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="b-wrapper-page__bottom">
					<div class="b-pagination">
						<ul class="b-pagination__list">
							<li class="b-pagination__item-prev">
								<a @click="prevPage()" :class="{'b-button': true, 'b-button--slider': true, 'b-button--disabled':pageNumber==0 }" href="javascript:void(0);" title="">
										<iconAngleleftSvg class="b-icon icon-angle-left"/>
								</a>
							</li>

								<li class="b-pagination__item">
										<span>{{resultCount > 0 ? pageNumber+1 : 0}} из {{resultCount}} стр</span>
								</li>

							<li class="b-pagination__item-next">
									<a @click="nextPage()" :class="{'b-button': true, 'b-button--slider': true, 'b-button--disabled':pageNumber >= resultCount-1 }" href="javascript:void(0);" title="">
										<iconAnglerightSvg class="b-icon icon-angle-left"/>
										</a>
							</li>
						</ul>
					</div>
					<div class="b-number-page">
						<div class="b-number-page__text">
							Показать кол-во строк
							<span></span>
						</div>
						<div class="b-number-page__text">
							<select @change="updateListEvent(size)" style="width:40px" v-model="size">
								<option :value="5">5</option>
								<option :value="10">10</option>
								<option :value="20">20</option>
								<option :value="30">30</option>
							</select>
						</div>
					</div>
				</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import block from '../../assets/icons/svg/lock.svg?inline'
import key from '../../assets/icons/svg/key.svg?inline'

import iconSvg from "../../assets/icons/svg/filter.svg"
import iconSearchSvg from "../../assets/icons/svg/search.svg"
import iconDownloadSvg from "../../assets/icons/svg/download.svg"
import iconSortdownSvg from "../../assets/icons/svg/sort-down.svg"
import iconSortupSvg from "../../assets/icons/svg/sort-up.svg"
import iconAngleleftSvg from "../../assets/icons/svg/angle-left.svg"
import iconAnglerightSvg from "../../assets/icons/svg/angle-right.svg"
import iconPlusSvg from "../../assets/icons/svg/plus.svg"
import iconEditSvg from "../../assets/icons/svg/edit.svg"
import iconLockSvg from "../../assets/icons/svg/lock.svg"
// import iconKeySvg from "../../assets/icons/svg/key.svg"
import iconCloseSvg from "../../assets/icons/svg/close.svg"

import adminButton from '../../components/AdminButon/index'
import {mapGetters, mapActions, mapMutations} from 'vuex'

export default {
	components: { block, adminButton, iconAngleleftSvg, iconCloseSvg, iconAnglerightSvg, iconSvg, iconSearchSvg, iconDownloadSvg, iconSortdownSvg, iconSortupSvg, iconPlusSvg, iconEditSvg, iconLockSvg, key},
	name: 'icn',
	computed: {
		...mapGetters({
			media: 'layout/getMedia',
			getUserInfo: 'auth/getUserInfo',
			// listCompanies: 'lc/getListCompanies',
			apiUrl: 'apiUrl'
		})
	},
	data: () => ({
		search: {
			name: '',
			inn: '',
			login: ''
		},
		size: 5,
		pageNumber: 0,
		resultCount: 0,
		clickFilter: 0,
		open: false,
		listCompaniesFilter: [],
		listCompanies: [],
		open1: false,
		checkColumn: 'role',
		columns: [
			{name: 'Наименование', view: true, sort: false, nameEng: 'name', click: false},
			{name: 'ИИН', view: true, sort: false, nameEng: 'id', click: true},
			{name: 'Адрес', view: true, sort: false, nameEng: 'address', click: false},
			{name: 'Телефон', view: true, sort: false, nameEng: 'phone', click: false},
			{name: 'E-mail', view: true, sort: false, nameEng: 'email', click: false},
		],
	}),
	methods: {
		...mapActions({
			getCompanies: 'lc/getCompanies',
			apiAll: 'api/apiAll'
		}),
		...mapMutations({
			sortMutation: 'lu/sortMutation'
		}),
		sortFunc(e) {
			e.sort = !e.sort
			this.checkColumn = e.nameEng
			this.sortMutation({column: e.nameEng, sort: e.sort ? 'asc' : 'desc'})
		},
		editRow(row) {
			this.$router.push(`/editCompanies/${row.id}`)
		},
		deleteItems(item){

          if(confirm('Вы уверены, что хотите удалить значение?')) {
				this.apiAll({type: 'delete', url: `api/Companies/${item.id}`, obj: {}, id: undefined })
			.then(res => {
				console.log(res);
				let index = this.listCompanies.findIndex((f) => f.id === item.id);
                  this.listCompanies.splice(index, 1);
			})
          }
      },
		nextPage(){
			this.pageNumber++;


			let columnsItem = this.columns.find(f => f.click);
			this.apiMethods(
				{ columnName: columnsItem.name, orderOperation: columnsItem.sort ? 'asc' : 'desc' },
				{ offsetRowCount: this.pageNumber !== 0 ? this.pageNumber*this.size : 0, fetchRowCount: this.size, use: true }
			)
		},
		prevPage(){
			this.pageNumber--;

			let columnsItem = this.columns.find(f => f.click);
			this.apiMethods(
				{ columnName: columnsItem.name, orderOperation: columnsItem.sort ? 'asc' : 'desc' },
				{ offsetRowCount: this.pageNumber !== 0 ? this.pageNumber*this.size : 0, fetchRowCount: this.size, use: true }
			)
		},
		apiMethods(orderByColumn, OffSetFetch) {
			this.isLoading = true
			this.apiAll({type: 'post', url: `api/Companies/filters`, obj: {
				email: this.search.email ? this.search.email : undefined,
				inn: this.search.inn ? this.search.inn : undefined,
				name: this.search.name ? this.search.name : undefined,
				orderByColumn: orderByColumn && orderByColumn.columnName ? orderByColumn : undefined,
				OffSetFetch: OffSetFetch ? OffSetFetch : undefined
			}, id: undefined})
			.then(res => {
				this.isLoading = false

				this.pageCount(this.listCompaniesFilter.length)

				this.listCompanies = res.data
			})
		},
		searchFunc() {
			this.isLoading = true
			this.isSearch = true

			let columnsItem = this.columns.find(f => f.click);
			this.apiMethods(
				{ columnName: columnsItem.name, orderOperation: columnsItem.sort ? 'asc' : 'desc' },
				{ offsetRowCount: this.pageNumber !== 0 ? this.pageNumber*this.size : 0, fetchRowCount: this.size, use: true }
			)
		},
		pageCount(count){
			console.log(count);
			this.resultCount = Math.ceil(count/this.size)
		},
		updateListEvent() {
			this.pageNumber = 0

			let columnsItem = this.columns.find(f => f.click);
			this.apiMethods(
				{ columnName: columnsItem.name, orderOperation: columnsItem.sort ? 'asc' : 'desc' },
				{ offsetRowCount: this.pageNumber !== 0 ? this.pageNumber*this.size : 0, fetchRowCount: this.size, use: true },
				this.clickFilter !== 0 ? this.clickFilter : null
			)
		},
	},
	created() {
		this.apiMethods(null, {offsetRowCount: 0, fetchRowCount:this.size, use: true})
		this.apiAll({type: 'get', url: `api/Companies`, id: undefined}).then(res => {
			this.listCompaniesFilter = res.data
			this.listCompanies.sort(function (a, b) {
			  if (a.name > b.name) return 1
			  if (a.name < b.name) return -1
			  return 0
			});
		})
	}
}
</script>

<style scoped>
@import '../../style/stylePages.css';

table tbody tr:hover {
	background: #f1f1f1;
	box-shadow: 0 1.5px 6px #00000080;
}
tr td {
	text-align: center!important;
}
tr th {
	text-align: center!important;
}

.pointer {
	cursor: pointer;
}
.b-filter__form, .b-filter__hidden {
	display: flex;
        align-items: start!important;
    }
</style>